@keyframes SlideSmooth{
    from{margin-right: 50vw;}
    to{margin-right: 0;}
}
.ExperienceCard_Main_Div{
    width: 60%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;

    border-left:  1px solid white;
    padding: 20px;
    border-radius: 0px;
    
    animation: SlideSmooth 0.7s;

}
.ExperienceCard_Main_Div .Left_Div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    border-right: 1px solid white;
    padding-right: 20px;
}
.ExperienceCard_Main_Div .Left_Div label{
    transition: 0.2s;
    font-family:"Rajdhani";
    font-size: 55px;
    color: white;
    font-weight:600 ;
}

.ExperienceCard_Main_Div .Right_Div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 5px;
}

.ExperienceCard_Main_Div .Right_Div .Image{
    /* width: 50%;
    height: auto; */
    height: 15vh;
    width: auto;
    border-radius: 10px;

}

.ExperienceCard_Main_Div .Right_Div label{
    transition: 0.2s;
    font-family:"Rajdhani";
    font-size: 40px;
    color: white;
}

.ExperienceCard_Main_Div .Right_Div p{
    transition: 0.2s;
    font-family:"Rajdhani";
    font-size: large;
    color: rgba(255, 255, 255, 0.684);
    margin-left: 5px;
}
.ExperienceCard_Main_Div .Right_Div button{
    transition: 0.2s;
    font-family:"Rajdhani";
    font-size: larger;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    padding: 10px;
    color: white;
    background-color: rgb(0, 0, 0);
    margin-top: 5px;
}

.ExperienceCard_Main_Div .Right_Div button:hover{
    transition: 0.2s;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.138);
}

@media (max-width: 901px){
    .ExperienceCard_Main_Div{
        width: 100%;
    
    
    }

    .ExperienceCard_Main_Div .Right_Div .Image{
        width: 15vh;
        height: auto;
        border-radius: 10px;
    
    }

    .ExperienceCard_Main_Div .Left_Div label{
        font-size: 30px;
    }
    
    
    .ExperienceCard_Main_Div .Right_Div label{
        font-size: x-large;
    }
    
}