.AboutMe_Main_Div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;

    width: 100%;
}

@keyframes Move{
    0%{margin-top:-100% ; bottom: 100%;}
    100%{margin-top: 0; bottom: 40%;}
}
.AboutMe_Main_Div{
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    animation: Move 1s;
    
}

.AboutMe_Main_Div .Left{
    display: flex;
    flex-direction: column;
    justify-content:  center;
    align-items: center;
    padding: 40px;
    gap: 20px;
}
.AboutMe_Main_Div .Left .Top .Image_Div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}
.AboutMe_Main_Div .Left .Top .Image_Div .ProfilePic{
    width: 180px;
    height: auto;
    border-radius: 20px;

    box-shadow: 2px 2px rgba(255, 255, 255, 0.144);
}

.AboutMe_Main_Div .Left .Top .Image_Div label{
    transition: 0.7s;
    font-family:"Rajdhani", "Josefin Sans", sans-serif;
    font-weight: 800;
    font-style: normal; 
    font-size: x-large;
    border-bottom:  2px solid #ffffff;
    color: rgb(255, 255, 255);
}
.AboutMe_Main_Div .Left .Top .Image_Div  label label{
    -webkit-text-stroke: 1px #cecece;
    font-family:"Rajdhani";
    border-bottom:  0;
}

.AboutMe_Main_Div .Left .Top{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:  center;
    align-items: flex-start;
    gap: 20px;

}

.AboutMe_Main_Div .Left .Top .content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:  center;
    align-items: flex-start;
    gap: 15px;
}

.AboutMe_Main_Div .Left .Top .content label{
    transition: 0.7s;
    font-family: "Rajdhani", sans-serif;
    font-weight: 200;
    font-style: normal; 
    font-size: x-large;
    border-bottom:  2px solid #ffffff;
    color: rgb(227, 227, 227);

}

.AboutMe_Main_Div .Left .Top .content label label{
    -webkit-text-stroke: 1px #cecece;
    border-bottom:  2px solid #ffffff;
    font-family:var(--Rajdhani);
}

.AboutMe_Main_Div .Left  .Social_Media_Div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    gap: 10%;
    width: 100%;
    height: 10%;
    border-top: 1px solid white;
    padding-top: 20px;
}

.AboutMe_Main_Div .Left .Social_Media_Div .icon{
    transition: 0.4s;
    color: white;
    font-size: 30px;
}

.AboutMe_Main_Div .Left .Social_Media_Div .icon:hover{
    transition: 0.4s;
    padding-bottom: 5px;
    /* transform: rotateY(180deg); */
    color: #FF6442;
}

.AboutMe_Main_Div .Right{
    display: flex;
    flex-direction: column;
    justify-content:  flex-start;
    align-items: center;
    padding: 40px;
    gap: 40px;
    width: 75%;
    max-height: 100vh;
    overflow-y: scroll;
}
/* .AboutMe_Main_Div .Right::-webkit-scrollbar{
    width: 2px;
    background-color: #000000;
}
.AboutMe_Main_Div .Right::-webkit-scrollbar-thumb{
    width: 2px;
    background-color: #ffe042;
} */
.AboutMe_Main_Div .Right .Title_Div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.AboutMe_Main_Div .Right .Title{
    transition: 0.7s;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 800;
    font-style: normal; 
    font-size:50px;
    border-bottom:  2px solid #FF6442;
    color: black;
    -webkit-text-stroke: 1px #FF6442;

}

.AboutMe_Main_Div .Right .backicon{
    transition: 0.2s;
    font-size: xx-large;
    color: #FF6442;
    animation:  Move 0.8s;
}
.AboutMe_Main_Div .Right .backicon:hover{
    transition: 0.2s;
    opacity: 0.5;
}

.AboutMe_Main_Div .Right p{
    transition: 0.7s;
    font-family:"Rajdhani";
    /* font-weight: 300; */
    font-style: normal; 
    font-size:25px;
    border-bottom:  2px solid #FF6442;
    color: rgb(249, 249, 249);
    /* -webkit-text-stroke: 1px #FF6442; */

    text-align: center;
    padding: 30px 10px;
    padding-top: 10px;
}

.AboutMe_Main_Div .Right .Links_Div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 30px;
    border-bottom:  2px solid #FF6442;
    width: 80%;
}

.AboutMe_Main_Div .Right .Links_Div .link{
    transition: 0.5s;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 800;
    font-style: normal; 
    font-size:35px;
    border:  1px solid #FF6442;
    padding: 10px;
    color: black;
    -webkit-text-stroke: 1px #FF6442;

    text-align: center;
}

.AboutMe_Main_Div .Right .Links_Div .link:hover{
    transition: 0.5s;
    color: #FF6442;
}

.AboutMe_Main_Div .Right .Links_Div2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding-bottom: 30px;
    /* border-bottom:  2px solid #ffe042; */
    width: 80%;
}

.AboutMe_Main_Div .Right .Links_Div2 .link{
    transition: 0.5s;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 800;
    font-style: normal; 
    font-size:35px;
    border:  1px solid #ffe042;
    padding: 10px;
    color: black;
    -webkit-text-stroke: 1px #ffe042;
    text-align: center;
}

.AboutMe_Main_Div .Right .Links_Div2 .link:hover{
    transition: 0.5s;
    color: #ffe042;
    border-radius: 10px;
}

.AboutMe_Main_Div .Awards_Div{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    gap: 20px;
}
.AboutMe_Main_Div .Awards_Div img{
    height: 300px;
    border-radius: 10px;
}

@media (max-width: 701px){
    .AboutMe_Main_Div{
        flex-direction: column; 
        justify-content: flex-start;
        align-items: center;

        height: auto;
    }
    .AboutMe_Main_Div .Left{
        width: auto;
        height: auto;
        max-height: auto;
        /* padding: 20px 10px; */
        /* max-height: 35vh; */
    }
    .AboutMe_Main_Div .Left .Top{
        flex-direction: row;
        justify-content: center;
        gap: 0px;
        /* align-items: center; */
    }
    .AboutMe_Main_Div .Left .Top .Image_Div{
        flex: 1;
        gap: 10px;
    }
    .AboutMe_Main_Div .Left .Top .Image_Div .ProfilePic{
        width: 80%;
    }
    .AboutMe_Main_Div .Left .Top .content{
        flex: 1;
        /* width: 50%; */
        display: flex;
        flex-direction: column;
        justify-content:  center;
        align-items: flex-start;
        gap: 10px;
    }
    .AboutMe_Main_Div .Left .Top .Image_Div label{
        font-size: medium;
    }
    .AboutMe_Main_Div .Left .Top .content label{
        font-size: medium;
    }
    .AboutMe_Main_Div .Right{
        width: 100%;
        padding-top: 0;
        gap: 20px;
        /* justify-content: flex-start; */
        overflow-y: hidden;
        max-height: 200vh;
        height: auto;
        padding: 5px 0;
    }
    .AboutMe_Main_Div .Right .Title{
        font-size: xx-large;
    }
    .AboutMe_Main_Div .Right p{
        font-size: large;
    }
    .AboutMe_Main_Div .Right .Links_Div .link{
        font-size: x-large;
    }
    .AboutMe_Main_Div .Right .Links_Div2{
        /* flex-direction: column; */
        gap: 15px;
    }
    .AboutMe_Main_Div .Right .Links_Div2 .link{
        font-size: x-large;
    }
    .AboutMe_Main_Div .Awards_Div{
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        gap: 20px;
    }
    .AboutMe_Main_Div .Awards_Div img{
        height: 32vw;
        border-radius: 10px;
    }
}